// @flow
import * as React from 'react'
import styled from 'styled-components'
import { colors } from '@components/shared/colors'
import { config } from 'react-awesome-styled-grid'
import Link from 'next/link'
import { FadeInImage } from '@components/shared/FadeInImage'

export type TagCardPropsType = {
  title: string,
  slug: string,
  imageUrl: string
}

export function TagCard (props: TagCardPropsType): React.Node {
  return (
    <TagCardContainer className='tag-card' href={props.slug} alt={props.title}>
      <TagCardTitle>{props.title}</TagCardTitle>
      <TagCardImage><FadeInImage src={props.imageUrl} width={100} height={100} /></TagCardImage>
    </TagCardContainer>
  )
}

const TagCardImage = styled.div`
  width: 48px;
  height: 48px;
  min-width: 48px;
  img {
    border-radius: 10px;
  }
  ${(props: any): string => config(props).media.md`
    width: 68px;
    height: 68px;
    min-width: 68px;
  `}
  ${(props: any): string => config(props).media.lg`
    width: 78px;
    height: 78px;
    min-width: 78px;
  `}
`

const TagCardTitle = styled.div`
  font-family: "Good Sans Bold";
  font-style: normal;
  font-weight: bold;
  line-height: 133%;
  letter-spacing: -0.3px;
  color: ${colors.white};

  font-size: 11px;
  margin-right: 6px;
  ${(props: any): string => config(props).media.sm`
    font-size: 12px;
  `}
  ${(props: any): string => config(props).media.md`
    font-size: 14px;
    margin-right: 8px;
  `}
  ${(props: any): string => config(props).media.lg`
    font-size: 16px;
  `}
`

const TagCardContainer = styled(Link)`
  display: flex;
  flex-direction: row;
  border: 0.5px solid ${colors.white};
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none;
  
  padding: 4px 4px 4px 8px;
  ${(props: any): string => config(props).media.sm`
    padding: 6px 6px 6px 12px;
  `}
  ${(props: any): string => config(props).media.xl`
    padding: 6px 6px 6px 24px;
  `}
`
