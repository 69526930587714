// @flow
import * as React from 'react'
import { withRouter } from 'next/router'
import type { DiscoverPagePropsType, DiscoverPageRowType } from '../../models/Discover'
import { getDiscoverPageProps } from '../../models/Discover'
import DiscoverNav from '@components/Discover/DiscoverNav'
import SmallSquareCardRow from '@components/Discover/SmallSquareCardRow'
import HorizontalLargeTrackRow from '@components/Discover/HorizontalLargeTrackRow'
import DeepLinkCardsCarousel from '@components/Discover/DeepLinkCardsCarousel'
import HorizontalMediumTrackRow from '@components/Discover/HorizontalMediumTrackRow'
import CharacterRow from '@components/shared/CharacterRow'
import HorizontalMediumBookRow from '@components/Discover/HorizontalMediumBookRow'
import DiscoverLayout from '@components/Discover/DiscoverLayout'
import { DiscoverMenu } from '@components/Discover/DiscoverMenu'
import { config } from 'react-awesome-styled-grid'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import type { AppStateType } from '@reducers/appstate'
import { userFetchRecommendationsSuccess } from '../../actions/userActions'
function Discover (props: DiscoverPagePropsType): React.Node {
  const userId = useSelector((state: AppStateType): string | null => state.user?.id)
  const recs = useSelector((state: AppStateType): DiscoverPageRowType[] => state.recommendations)
  const dispatch = useDispatch()
  React.useEffect((): void => {
    if (userId) {
      getDiscoverPageProps().then((recs: DiscoverPagePropsType): void => {
        dispatch(userFetchRecommendationsSuccess(recs))
      })
    }
  }, [userId])
  return (
    <DiscoverLayout background={true} title={`Browse Top Audiobooks on Dipsea`}>
      <div>
        <DiscoverMenu page={'discover'}/>
        {(recs.length ? recs : props.pageRows).map((rec: DiscoverPageRowType, index: number): React.Node => (
          <div key={`discover-${rec.displayType}-${index}`}>
            {rec.displayType === 'discoverNavBlock' && <SectionDivider><DiscoverNav {...rec}/></SectionDivider>}
            {rec.displayType === 'deepLinkedSmallSquareCardsRow' && <SectionDivider><SmallSquareCardRow {...rec}/> </SectionDivider>}
            {rec.displayType === 'horizontalLargeTrackRow' && <SectionDivider><HorizontalLargeTrackRow {...rec}/></SectionDivider> }
            {rec.displayType === 'horizontalMediumTrackRow' && <SectionDivider><HorizontalMediumTrackRow {...rec}/></SectionDivider> }
            {rec.displayType === 'deepLinkedCardsCarousel' && <SectionDivider><DeepLinkCardsCarousel {...rec}/></SectionDivider> }
            {rec.displayType === 'characterRow' && <SectionDivider><CharacterRow {...rec}/></SectionDivider> }
            {rec.displayType === 'horizontalMediumBookRow' && <SectionDivider><HorizontalMediumBookRow {...rec}/></SectionDivider> }
          </div>
        ))}
      </div>
    </DiscoverLayout>
  )
}

export default withRouter(Discover)

export async function getStaticProps (): Promise<{ props: DiscoverPagePropsType }> {
  const recs: DiscoverPagePropsType = await getDiscoverPageProps()
  return { props: recs }
}

const SectionDivider = styled.div`
    width: 100%;
    margin-top: 32px;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
      margin-top: 48px;
    `}
    ${(props: any): string => config(props).media.lg`
      margin-top: 56px;
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`
