// @flow
import * as React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { config } from 'react-awesome-styled-grid'
import { colors } from '@components/shared/colors'
import type { DeepLinkedCardsCarouselPropsType, DeepLinkedCardsItemPropsType } from '../../models/Discover'
import { HeaderSeeAll } from '@components/shared/HeaderSeeAll'
import TrackTags from '@components/Tag/TrackTags'
import { replaceEpisodeWithChapter } from '../../models/Discover'

function DeepLinkCardsCarousel (props: DeepLinkedCardsCarouselPropsType): React.Node {
  const router = useRouter()
  return (
    <>
      <HeaderSeeAll title={props.title} cta={props.cta} description={props.description}/>
      <RowContainer>
        {props.items.map((item: DeepLinkedCardsItemPropsType, index: number): React.Node => {
          return (<CardItem key={`square-card-${index}`}>
            <ImageContainer onClick={(): void => router.push(item.slug)}>
              <Art url={item.imageUrl} role="img" aria-label={item.title}>
                {item.badgeText && (<BadgeText>{item.badgeText}</BadgeText>)}
              </Art>
            </ImageContainer>
            <HeaderTitleText><Link href={item.slug}>{item.title}</Link></HeaderTitleText>
            <DescriptionContainer onClick={(): void => router.push(item.slug)}>{item.description}</DescriptionContainer>
            {item.tags && <TrackTags tags={item.tags} clickable={true} displayTagCount={3}/>}
            <DescriptionContainer className={'white'}>{replaceEpisodeWithChapter(item.description2)}</DescriptionContainer>
          </CardItem>)
        })}
      </RowContainer>
    </>
  )
}

export default DeepLinkCardsCarousel

const DescriptionContainer = styled.div`
    font-family: "Good Sans Regular";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.3px;
    color: ${colors.white70};
    margin-bottom: 7px;
    margin-top: 7px;
    &.white {
        color: ${colors.white};
    }
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
  `}
    ${(props: any): string => config(props).media.lg`
    font-size: 16px;
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`

const RowContainer = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.md`
      gap: 16px;
  `}
    ${(props: any): string => config(props).media.lg`
      gap: 20px;
    `}
`

const CardItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`

const ImageContainer = styled.div`
    width: 300px;
    position: relative;
    z-index: 0;
    img {
        border-radius: 10px;
    }

    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
    width: 400px;
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`

const Art = styled.div`
  background-image: url(${(props: any): any => props.url});
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-bottom: calc((293 / 420) * 100%);
  box-sizing: border-box;
  margin: 0 auto;
`

const BadgeText = styled.div`
    font-family: "Good Sans Regular";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.3px;
    color: ${colors.white};
    position: absolute;
    background-color: ${colors.midnightBlue70};
    border-radius: 100px;
    border: 0.5px solid ${colors.white};
    top: 13px;
    left: 13px;
    z-index: 1;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
      font-size: 12px;
    `}
    ${(props: any): string => config(props).media.lg`
      font-size: 14px;
    `}
    ${(props: any): string => config(props).media.xl`
  `}
`

const HeaderTitleText = styled.div`
    a {
        margin-top: 20px;
        color: ${colors.white};
        font-family: "Good Sans Bold";
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-decoration: none;
        letter-spacing: -0.3px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        ${(props: any): string => config(props).media.md`
          margin-top: 35px;
        `}
        ${(props: any): string => config(props).media.lg`
          font-size: 18px;
          letter-spacing: 0px;
        `}
    }
`
