// @flow
import * as React from 'react'
import styled from 'styled-components'
import { config } from 'react-awesome-styled-grid'
import type { TagCardPropsType } from '@components/Tag/TagCard'
import { TagCard } from '@components/Tag/TagCard'

type PropsType = {
  tags: TagCardPropsType[]
}

export function TagCardRow (props: PropsType): React.Node {
  return (
    <TagCardRowContainer>
      {props.tags.map((item: TagCardPropsType): React.Node => (
        <TagCard {...item} key={`search-section-${item.slug}`}/>
      ))}
    </TagCardRowContainer>
  )
}

const TagCardRowContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;

  .tag-card {
    flex: 0 0 100%;
  }

  @media only screen and (min-width: 300px) {
    .tag-card {
      flex: 0 0 calc(50% - 5px);
      max-width: calc(50% - 5px);
    }
  }

  ${(props: any): string => config(props).media.sm`
    gap: 12px;
    .tag-card {
      flex: 0 0 calc(50% - 6px);
      max-width: unset;
    }
  `}

  @media only screen and (min-width: 650px) {
    .tag-card {
      flex: 0 0 calc(33% - 7px);
    }
  }

  ${(props: any): string => config(props).media.lg`
    gap: 16px;
    .tag-card {
      flex: 0 0 calc(33% - 8px);
      max-width: 350px;
    }
  `}

  ${(props: any): string => config(props).media.xl`
    justify-content: flex-start;
    .tag-card {
      flex: 0 0 1;
      max-width: 300px;
    }
  `}
`
