// @flow
import * as React from 'react'
import type { HorizontalLargeTrackRowPropsType } from '../../models/Discover'
import { FadeInImage } from '@components/shared/FadeInImage'
import Link from 'next/link'
import type { TrackPropsType } from '../../models/Track'
import { config } from 'react-awesome-styled-grid'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { HeaderSeeAll } from '@components/shared/HeaderSeeAll'
import { colors } from '@components/shared/colors'
import TrackTags from '@components/Tag/TrackTags'
function HorizontalLargeTrackRow (props: HorizontalLargeTrackRowPropsType): React.Node {
  const router = useRouter()
  return (
    <div>
      <HeaderSeeAll title={props.title} cta={props.cta}/>
      <RowContainer>
        {props.items.map((item: TrackPropsType, index: number): React.Node => {
          return (<CardItem key={`hositonal-large-track-row-${item.id}-${index}`}>
            <ImageContainer onClick={(): void => router.push(item.slug)}>
              <FadeInImage src={item.images.img500} width={500} height={500} alt={item.title} />
            </ImageContainer>
            <HeaderTitleText>
              <Link href={item.slug}>{item.title}</Link>
            </HeaderTitleText>
            {item.series?.[0] && (<SeriesInfo>{item.series?.[0]?.episodeInfo} <Link href={item.series[0].slug} passHref={true}>{item.series[0].title}</Link></SeriesInfo>)}
            <TagsContainerMobile>
              <TrackTags tags={item.tags} maxLines={3}/>
            </TagsContainerMobile>
            <TagsContainerTablet>
              <TrackTags tags={item.tags} maxLines={4} clickable={true}/>
            </TagsContainerTablet>
          </CardItem>)
        })}
      </RowContainer>
    </div>
  )
}

export default HorizontalLargeTrackRow

const SeriesInfo = styled.div`
    margin-top: 8px;
    margin-bottom: 8px;
    color: ${colors.white};
    font-family: "Good Sans Regular";
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
    letter-spacing: -0.3px;
    font-size: 13px;
    ${(props: any): string => config(props).media.md`
          font-size: 16px;
          margin-top: 16px;
    `}

    a {
        color: ${colors.tintOrange};
        text-decoration: underline;
    }
`

const RowContainer = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.md`
      gap: 16px;
  `}
    ${(props: any): string => config(props).media.lg`
      gap: 20px;
    `}
`

const CardItem = styled.div`
    display: flex;
    flex-direction: column;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`

const ImageContainer = styled.div`
    width: 200px;
    height: 200px;
    position: relative;
    z-index: 0;
    img {
        border-radius: 10px;
    }
    margin-bottom: 8px;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
      width: 268px;
      height: 268px;
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`

const HeaderTitleText = styled.div`
    a {
        margin-top: 12px;
        color: ${colors.white};
        font-family: "Good Sans Bold";
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-decoration: none;
        letter-spacing: -0.3px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        ${(props: any): string => config(props).media.md`
          margin-top: 16px;
        `}
        ${(props: any): string => config(props).media.md`
          margin-top: 16px;
        `}
        ${(props: any): string => config(props).media.lg`
          font-size: 18px;
          margin-top: 20px;
          letter-spacing: 0px;
        `}
    }
`

const TagsContainerMobile = styled.div`
    display: inline;
    ${(props: any): string => config(props).media.md`
    display: none;
  `}
`

const TagsContainerTablet = styled.div`
    display: none;
  ${(props: any): string => config(props).media.md`
    display: inline;
  `}
`
