// @flow
import * as React from 'react'
import type { ShortCutItemsPropsType } from '../../models/Discover'
import { TagCardRow } from '@components/Tag/TagCardRow'

function DiscoverNav (props: ShortCutItemsPropsType): React.Node {
  return (
    <TagCardRow tags={props.items}/>
  )
}

export default DiscoverNav
