// @flow
import * as React from 'react'
import type { CharacterRowPropsType } from '../../models/Discover'
import { FadeInImage } from '@components/shared/FadeInImage'
import type { CharacterInfoPropsType } from '../../models/Character'
import { config } from 'react-awesome-styled-grid'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { colors } from '@components/shared/colors'
import { HeaderSeeAll } from '@components/shared/HeaderSeeAll'
function CharacterRow (props: CharacterRowPropsType): React.Node {
  const router = useRouter()
  return (
    <div>
      <HeaderSeeAll title={props.title} cta={props.cta} description={props.description}/>
    <RowContainer>
      {props.items.map((item: CharacterInfoPropsType, index: number): React.Node => {
        if (!item.imageUrl || !item.signatureImageUrl) {
          return null
        }
        return (<CardItem key={`circular-row-${index}`} onClick={(): void => router.push(item.slug)}>
            <ImageContainer>
              <FadeInImage src={item.imageUrl} width={200} height={200} alt={item.title}/>
            </ImageContainer>
          <SignatureContainer>
            <FadeInImage src={item.signatureImageUrl} width={200} height={200} alt={item.title}/>
          </SignatureContainer>
          <DescriptionContainer>{item.description}</DescriptionContainer>
        </CardItem>)
      })}
    </RowContainer>
    </div>
  )
}

export default CharacterRow

const RowContainer = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
      gap: 16px;
    `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`

const CardItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`

const DescriptionContainer = styled.div`
    font-family: "Good Sans Regular";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.3px;
    margin-top: 12px;
    color: ${colors.white};
    width: 165px;
    text-align: center;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
      width: 215px;
  `}
    ${(props: any): string => config(props).media.md`
      width: 225px;
  `}
    ${(props: any): string => config(props).media.lg`
      width: 235px;
      font-size: 16px;
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`

const ImageContainer = styled.div`
    width: 100px;
    height: 100px;
    position: relative;
    z-index: 0;
    
    img {
        border-radius: 10px;
    }
    margin-bottom: 8px;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
      width: 150px;
      height: 150px;
    `}
    ${(props: any): string => config(props).media.md`
      width: 160px;
      height: 160px;
  `}
    ${(props: any): string => config(props).media.lg`
      width: 170px;
      height: 170px;
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`

const SignatureContainer = styled.div`
    width: 90px;
    height: 45px;
    position: relative;
    z-index: 0;
    
    margin-top: 16px;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`
