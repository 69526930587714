// @flow
import * as React from 'react'
import type { SquareCardPropsType, SquareCardItemPropsType } from '../../models/Discover'
import { FadeInImage } from '@components/shared/FadeInImage'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { config } from 'react-awesome-styled-grid'
import { colors } from '@components/shared/colors'
import { HeaderSeeAll } from '@components/shared/HeaderSeeAll'
import { replaceEpisodeWithChapter } from '../../models/Discover'
function SmallSquareCardRow (props: SquareCardPropsType): React.Node {
  const router = useRouter()
  return (
    <>
      <HeaderSeeAll title={props.title} cta={null}/>
      <RowContainer>
        {props.items.map((item: SquareCardItemPropsType, index: number): React.Node => {
          return (<CardItem key={`square-card-${index}`} onClick={(): void => router.push(item.slug)}>
            <ImageContainer>
            {item.badgeText && (<BadgeText>{item.badgeText}</BadgeText>)}
            <FadeInImage src={item.imageUrl} width={500} height={500} alt={item.title} />
            </ImageContainer>
            <Link href={item.slug}>{item.title}</Link>
            <DescriptionContainer>{replaceEpisodeWithChapter(item.description)}</DescriptionContainer>
          </CardItem>)
        })}
      </RowContainer>
    </>
  )
}

export default SmallSquareCardRow

const RowContainer = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.md`
      gap: 16px;
  `}
    ${(props: any): string => config(props).media.lg`
      gap: 20px;
    `}
`

const CardItem = styled.div`
    display: flex;
    flex-direction: column;
    a {
        font-family: "Good Sans Regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: ${colors.white};
        text-decoration: none;
        margin: 13px 0px 6px 0px;

        ${(props: any): string => config(props).media.md`
          font-size: 16px;
        `}
        ${(props: any): string => config(props).media.lg`
          font-size: 18px;
          margin: 20px 0px 12px 0px;
        `}
        &:hover {
            text-decoration: underline;
        }
    }
`

const DescriptionContainer = styled.div`
  font-family: "Good Sans Regular";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.3px;
  color: ${colors.white70};
  ${(props: any): string => config(props).media.xs`
  `}
  ${(props: any): string => config(props).media.sm`
  `}
  ${(props: any): string => config(props).media.md`
  `}
  ${(props: any): string => config(props).media.lg`
    font-size: 16px;
  `}
  ${(props: any): string => config(props).media.xl`
  `}
`
const ImageContainer = styled.div`
    width: 105px;
    height: 105px;
    position: relative;
    z-index: 0;
    img {
        border-radius: 10px;
    }
    margin-bottom: 8px;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
      width: 136px;
      height: 136px;
  `}
    ${(props: any): string => config(props).media.md`
      width: 200px;
      height: 200px;
  `}
    ${(props: any): string => config(props).media.lg`
      width: 226px;
      height: 226px;
  `}
`

const BadgeText = styled.div`
    font-family: "Good Sans Regular";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.3px;
    color: ${colors.white};
    position: absolute;
    background-color: ${colors.midnightBlue70};
    border-radius: 100px;
    border: 0.5px solid ${colors.white};
    top: 13px;
    left: 13px;
    z-index: 1;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
      font-size: 12px;
    `}
    ${(props: any): string => config(props).media.lg`
      font-size: 14px;
    `}
    ${(props: any): string => config(props).media.xl`
  `}
`
