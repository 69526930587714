// @flow

import { getDiscoverRecommendations, getLibrary } from '../services/dipseaApi'
import type { DeepLinkType, DipseaContentType, RecommendationV5Type } from '../flowTypes'
import { convertDeepLink } from './helpers'
import { convertTrackToProps } from './Track'
import type { TrackPropsType } from './Track'
import { convertCategoryToCharacterInfoProps } from './Character'
import type { CharacterInfoPropsType } from './Character'
import type { BookInfoPropsType } from './Book'
import { getBookInfoProps } from './Book'
import type { TagInfoType } from './Tag'
import type { ThematicTagInfoType } from './ThematicLandingPage'
import { getAudioBookThematicTags } from './ThematicLandingPage'

export type ShortCutItemType = {
  slug: string,
  title: string,
  imageUrl: string
}

export type ShortCutItemsPropsType = {
  displayType: 'discoverNavBlock',
  items: ShortCutItemType[]
}

export function convertNavBarRectoProps (items: DeepLinkType[]): ShortCutItemsPropsType {
  if (items.length === 2) { // listens/favorites default
    return ({
      displayType: 'discoverNavBlock',
      items: [{
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/dipsea-webassets/o/category-icon-images%2Fnew-releases-category-icon-updated.png?alt=media&token=3d1f2670-4f91-4b63-8af7-87b07ec58dfd',
        slug: '/stories/new-releases',
        title: 'New Releases'
      },
      {
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/dipsea-images-resized/o/500-MA11_KillianSendsYourASexyVoiceMemo_Midjourney.png?alt=media&token=dac2d35b-ed5a-4fb3-9405-f711ab6f6595',
        slug: '/tags/just-moans-tag',
        title: 'Moan Audios'
      },
      {
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/dipsea-webassets/o/top-series.png?alt=media&token=128c7277-5032-4f1f-a558-d01edbf508a4',
        slug: '/series',
        title: 'Top Series'
      },
      {
        imageUrl: 'https://images.ctfassets.net/03of3q5qzesq/20jZYopIqd9IsKmUgYKUkp/d40866785bd0885d7d7b2098c7ddbac1/Her___Him.png',
        slug: '/tags/her-him-tag',
        title: 'Her + Him'
      },
      {
        imageUrl: 'https://storage.googleapis.com/dipsea-images/300x300-Tag-WLW.png',
        slug: '/tags/wlw-tag',
        title: 'WLW'
      },
      {
        imageUrl: 'https://api.dipseastories.com/images/100-614_GIWJay_SuzanneDias-min.jpg',
        slug: '/characters',
        title: 'Top Characters'
      }
      ]
    })
  }
  return ({
    displayType: 'discoverNavBlock',
    items: items.map((item: DeepLinkType): ShortCutItemType => {
      return {
        slug: convertDeepLink(item.deepLinkUrl),
        title: item.title,
        imageUrl: item.imageUrl
      }
    })
  })
}
export type SquareCardItemPropsType = {
  title: string,
  slug: string,
  imageUrl: string,
  badgeText: string | null,
  description: string
}

export function replaceEpisodeWithChapter (text: string | null): string {
  if (!text) {
    return ''
  }
  return text.replace('Episode', 'Chapter')
}

export type SquareCardPropsType = {
  title: string,
  displayType: 'deepLinkedSmallSquareCardsRow',
  items: SquareCardItemPropsType[]
}
export function convertSquareCards (rec: RecommendationV5Type): SquareCardPropsType {
  return {
    title: rec.title,
    displayType: 'deepLinkedSmallSquareCardsRow',
    items: rec.items.map((item: DeepLinkType): SquareCardItemPropsType => {
      return {
        title: item.title,
        slug: convertDeepLink(item.deepLinkUrl),
        imageUrl: item.imageUrl,
        badgeText: item.badgeText || null,
        description: item.description
      }
    })
  }
}

export type HorizontalLargeTrackRowPropsType = {
  title: string,
  displayType: 'horizontalLargeTrackRow',
  description?: string,
  items: TrackPropsType[],
  cta: {
    title: string,
    url: string
  } | null
}

export function convertHorizontalLargeTrackRow (rec: RecommendationV5Type, library: DipseaContentType): HorizontalLargeTrackRowPropsType {
  const items = rec.ids.map((id: string): TrackPropsType => {
    return convertTrackToProps(library.tracks[id], library)
  }).filter((track: TrackPropsType | null): boolean => !!track)
  const cta = rec.cta
    ? {
        title: rec.cta.name,
        url: convertDeepLink(rec.cta.deepLink)
      }
    : null
  return {
    title: rec.title,
    displayType: 'horizontalLargeTrackRow',
    items,
    cta
  }
}

export type HorizontalMediumTrackRowPropsType = {
  title: string,
  displayType: 'horizontalMediumTrackRow',
  description: string | null,
  items: TrackPropsType[],
  cta: {
    title: string,
    url: string
  } | null
}

export function convertHorizontalMediumTrackRow (rec: RecommendationV5Type, library: DipseaContentType): HorizontalMediumTrackRowPropsType {
  const items = rec.ids.map((id: string): TrackPropsType => {
    return convertTrackToProps(library.tracks[id], library)
  }).filter((track: TrackPropsType | null): boolean => !!track)
  const cta = rec.cta
    ? {
        title: rec.cta.name,
        url: convertDeepLink(rec.cta.deepLink)
      }
    : null
  return {
    title: rec.header || rec.title,
    displayType: 'horizontalMediumTrackRow',
    items,
    cta,
    description: rec.description || null
  }
}

export type DeepLinkedCardsItemPropsType = {
  title: string,
  slug: string,
  imageUrl: string,
  badgeText: string | null,
  description: string,
  description2: string | null,
  tags: ThematicTagInfoType[] | TagInfoType[] | null
}

export type DeepLinkedCardsCarouselPropsType = {
  title: string,
  displayType: 'deepLinkedCardsCarousel',
  description: string | null,
  cta?: {
    title: string,
    url: string
  } | null,
  items: DeepLinkedCardsItemPropsType[]
}
export function convertDeepLinkedCardsCarousel (rec: RecommendationV5Type, library: DipseaContentType): DeepLinkedCardsCarouselPropsType {
  const cta = rec.cta
    ? {
        title: rec.cta.name,
        url: convertDeepLink(rec.cta.deepLink)
      }
    : null
  return {
    title: rec.title,
    displayType: 'deepLinkedCardsCarousel',
    description: rec.description || null,
    items: rec.items.map((item: DeepLinkType): DeepLinkedCardsItemPropsType => {
      let tags = null
      let description = item.description
      if (item.type === 'series') {
        tags = getAudioBookThematicTags(item.id, library) || []
        description = library.series[item.id].description
        if (description.length >= 150) {
          description = description.substring(0, 150).trim() + '...'
        }
      }
      return {
        title: item.title,
        slug: convertDeepLink(item.deepLinkUrl),
        imageUrl: item.imageUrl,
        badgeText: item.badgeText || null,
        description,
        description2: item.description2 || null,
        tags
      }
    }),
    cta
  }
}

export type CharacterRowPropsType = {
  title: string,
  displayType: 'characterRow',
  description: string | null,
  items: CharacterInfoPropsType[],
  cta: {
    title: string,
    url: string
  } | null
}
export function convertCircularRow (rec: RecommendationV5Type, library: DipseaContentType): CharacterRowPropsType {
  const cta = rec.cta
    ? {
        title: rec.cta.name,
        url: convertDeepLink(rec.cta.deepLink)
      }
    : null
  return {
    title: rec.title,
    displayType: 'characterRow',
    description: rec.description || null,
    items: rec.ids.filter((id: string): boolean => !!library.contexts[id]).map((id: string): CharacterInfoPropsType => {
      return convertCategoryToCharacterInfoProps(library.contexts[id])
    }).filter((character: CharacterInfoPropsType): boolean => !!character.imageUrl),
    cta
  }
}

export type HorizontalMediumBookRowPropsType = {
  title: string,
  displayType: 'horizontalMediumBookRow',
  items: BookInfoPropsType[],
  description: string | null,
  cta: {
    title: string,
    url: string
  } | null
}

export function convertHorizontalMediumBookRow (rec: RecommendationV5Type, library: DipseaContentType): HorizontalMediumBookRowPropsType {
  const cta = rec.cta
    ? {
        title: rec.cta.name,
        url: convertDeepLink(rec.cta.deepLink)
      }
    : null
  return {
    title: rec.title,
    displayType: 'horizontalMediumBookRow',
    description: rec.description || null,
    items: rec.ids.map((id: string): BookInfoPropsType => {
      return getBookInfoProps(id, library)
    }),
    cta
  }
}

export type DiscoverPageRowType = ShortCutItemsPropsType |
  SquareCardPropsType |
  HorizontalLargeTrackRowPropsType |
  CharacterRowPropsType |
  HorizontalMediumBookRowPropsType |
  HorizontalMediumTrackRowPropsType |
  DeepLinkedCardsCarouselPropsType

export type DiscoverPagePropsType = {
  title: string,
  pageRows: DiscoverPageRowType[]
}

export function convertDiscoverRecommendationsProps (recs: RecommendationV5Type[], library: DipseaContentType): DiscoverPageRowType[] {
  const items: DiscoverPageRowType[] = []
  recs.forEach((rec: RecommendationV5Type): void => {
    switch (rec.displayType) {
      case 'discoverNavBlock':
        items.push(convertNavBarRectoProps(rec.items))
        break
      case 'deepLinkedSmallSquareCardsRow':
        items.push(convertSquareCards(rec))
        break
      case 'horizontalLargeTrackRow':
        items.push(convertHorizontalLargeTrackRow(rec, library))
        break
      case 'horizontalMediumTrackRow':
        items.push(convertHorizontalMediumTrackRow(rec, library))
        break
      case 'circularRow':
        items.push(convertCircularRow(rec, library))
        break
      case 'deepLinkedCardsCarousel':
        items.push(convertDeepLinkedCardsCarousel(rec, library))
        break
      case 'horizontalMediumBookRow':
        items.push(convertHorizontalMediumBookRow(rec, library))
        break
      default:
        break
    }
  })
  return items
}

export async function getDiscoverPageProps (): Promise<DiscoverPagePropsType> {
  const [recs, library] = await Promise.all([getDiscoverRecommendations(), getLibrary()])
  const pageRows = convertDiscoverRecommendationsProps(recs, library)
  return {
    title: 'Discover',
    pageRows
  }
}
