// @flow
import * as React from 'react'
import type { HorizontalMediumBookRowPropsType } from '../../models/Discover'
import { FadeInImage } from '@components/shared/FadeInImage'
import Link from 'next/link'
import type { BookInfoPropsType } from '../../models/Book'
import styled from 'styled-components'
import { config } from 'react-awesome-styled-grid'
import { useRouter } from 'next/router'
import { HeaderSeeAll } from '@components/shared/HeaderSeeAll'
import { colors } from '@components/shared/colors'

function HorizontalMediumBookRow (props: HorizontalMediumBookRowPropsType): React.Node {
  const router = useRouter()
  return (
    <RecRowContainer>
      <HeaderSeeAll title={props.title} cta={props.cta} description={props.description}/>
      <RowContainer>
        {props.items.map((item: BookInfoPropsType, index: number): React.Node => {
          return (<CardItem key={`book-row-${item.id}-${index}`} onClick={(): void => router.push(item.slug)}>
              <ImageContainer>
                <FadeInImage src={item.imageUrl} width={200} height={300} alt={item.title}/>
              </ImageContainer>
              <HeaderTitleText>
                <Link href={item.slug} passHref={true}>
                  {item.title}
                </Link>
              </HeaderTitleText>
            <DescriptionContainer>{item.shortDescription}</DescriptionContainer>
          </CardItem>)
        })}
      </RowContainer>
    </RecRowContainer>
  )
}

export default HorizontalMediumBookRow

const RecRowContainer = styled.div`
  width: 100%;
`

const RowContainer = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.md`
      gap: 16px;
  `}
    ${(props: any): string => config(props).media.lg`
      gap: 20px;
    `}
`

const CardItem = styled.div`
    display: flex;
    flex-direction: column;
`

const ImageContainer = styled.div`
    width: 163px;
    height: 244px;
    position: relative;
    z-index: 0;
    
    margin-bottom: 8px;
    
    ${(props: any): string => config(props).media.sm`
      width: 183px;
      height: 274px;
    `}
    ${(props: any): string => config(props).media.md`
      width: 200px;
      height: 300px;
  `}
    ${(props: any): string => config(props).media.lg`
      width: 200px;
      height: 300px;
  `}
`
const HeaderTitleText = styled.div`
    a {
        margin-top: 12px;
        color: ${colors.white};
        font-family: "Good Sans Bold";
        font-style: normal;
        font-weight: 700;
        line-height: 125%;
        text-decoration: none;
        letter-spacing: -0.3px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        ${(props: any): string => config(props).media.md`
          margin-top: 16px;
        `}
        ${(props: any): string => config(props).media.md`
          margin-top: 16px;
        `}
        ${(props: any): string => config(props).media.lg`
          font-size: 18px;
          margin-top: 20px;
          letter-spacing: 0px;
        `}
    }
`

const DescriptionContainer = styled.div`
  font-family: "Good Sans Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 136%;
  color: ${colors.white70};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-top: 8px;
  ${(props: any): string => config(props).media.xs`
  `}
  ${(props: any): string => config(props).media.sm`
  `}
  ${(props: any): string => config(props).media.md`
  `}
  ${(props: any): string => config(props).media.lg`
    font-size: 16px;
  `}
  ${(props: any): string => config(props).media.xl`
  `}
`
