// @flow
import * as React from 'react'
import styled from 'styled-components'
import { colors } from '@components/shared/colors'
import { config } from 'react-awesome-styled-grid'
import Link from 'next/link'
import AccountIcon from '@components/shared/icons/AccountIcon'

type PropsType = {
  page: 'read' | 'discover' | 'sleep' | 'wellness'
}

export function DiscoverMenu (props: PropsType): React.Node {
  const [sticky, setSticky] = React.useState<boolean>(false)
  React.useEffect((): () => void => {
    const scrollListener = window.addEventListener('scroll', (): void => {
      if (window.scrollY > 88) {
        setSticky(true)
      } else {
        setSticky(false)
      }
    })
    return (): void => {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [])

  return (
    <DiscoverMenuContainer>
      <TitleContainer>
        <Title>Discover</Title>
        <IconContainer><Link href={'/account'}><AccountIcon size={22}/></Link></IconContainer>
      </TitleContainer>
      <MenuContainer $sticky={sticky}>
        <MenuItem $selected={props.page === 'discover'}><Link href={'/discover'}>Audiobooks</Link></MenuItem>
        <MenuItem $selected={props.page === 'sleep'}><Link href={'/sleep'}>Sleep</Link></MenuItem>
        <MenuItem $selected={props.page === 'read'}><Link href={'/books'}>Read</Link></MenuItem>
        <MenuItem $selected={props.page === 'wellness'}><Link href={'/wellness'}>Wellness</Link></MenuItem>
      </MenuContainer>
      <StickyDiscoverMenu $sticky={sticky}>
        <MenuItem $selected={props.page === 'discover'}><Link href={'/discover'}>Audiobooks</Link></MenuItem>
        <MenuItem $selected={props.page === 'sleep'}><Link href={'/sleep'}>Sleep</Link></MenuItem>
        <MenuItem $selected={props.page === 'read'}><Link href={'/books'}>Read</Link></MenuItem>
        <MenuItem $selected={props.page === 'wellness'}><Link href={'/wellness'}>Wellness</Link></MenuItem>
      </StickyDiscoverMenu>
    </DiscoverMenuContainer>
  )
}

const StickyDiscoverMenu = styled.div`
  ${(props: any): string => {
    if (props.$sticky) {
      return `
        display: flex;
        align-items: center;
        padding-left: 20px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        backdrop-filter: blur(20px);
        height: 40px;
        z-index: 5;
        `
    }
    return 'display: none;'
  }};
  ${(props: any): string => config(props).media.sm`
    padding-left: 40px;
  `}  
  ${(props: any): string => config(props).media.md`
    display: none;
  `}
`
const DiscoverMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 19px 0;
  ${(props: any): string => config(props).media.md`
    margin: 0 0 38px 0;
    flex-direction: row;
    align-items: center;
  `}
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 32px 0 19px 0;
  ${(props: any): string => config(props).media.md`
    justify-content: none;
  `}
`
const IconContainer = styled.div`
  ${(props: any): string => config(props).media.md`
    display: none;
  `}
`
const Title = styled.h1`
  margin: 0;
  color: ${colors.white};
  font-family: "Queens Condensed Light";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  ${(props: any): string => config(props).media.sm`
    font-size: 45px;
  `}
  ${(props: any): string => config(props).media.md`
    font-size: 60px;
  `}
`

const MenuContainer = styled.div`
  display: flex;
  ${(props: any): string => config(props).media.md`
    margin-left: 40px;
  `}

  ${(props: any): ?string => {
      if (props.$sticky) {
        return `opacity: 0;`
      }
    }
  }
`

const MenuItem = styled.div`
  margin-right: 20px;
  a {
    font-family: "Good Sans Bold";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: -0.3px;
    text-decoration: none;
    color: ${colors.white70};
    ${(props: any): string => {
      if (props.$selected) {
        return `
          text-decoration: underline;
          text-underline-offset: 6px;
          text-decoration-thickness: 2px;
          color: ${colors.tintOrange};
        `
      }
      return ''
    }}
    &:hover {
        color: ${colors.orange};
    }
  }
  ${(props: any): string => config(props).media.md`
    padding-top: 18px;
  `}
`
